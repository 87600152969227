export const FIELD_IS_REQUIRED = 'OFieldIsRequired';
export const FIELD_IS_NOT_VALID = 'OFieldIsNotValid';

export const SETTINGS = 'Settings';

export const FIRST_NAME = 'FirstName';
export const FIRST_NAME_EXAMPLE = 'FirstName_Example';
export const ENTER_VALID_FIRST_NAME = 'PleaseEnterAValidFirstName';
export const FIRST_NAME_MAX_CHARS = 'FirstNameCharMax';

export const LAST_NAME = 'LastName';
export const LAST_NAME_EXAMPLE = 'LastName_Example';
export const ENTER_VALID_LAST_NAME = 'PleaseEnterAValidLastName';
export const LAST_NAME_MAX_CHARS = 'LastNameCharMax';

export const DISPLAY_NAME = 'DisplayName';
export const DISPLAY_NAME_NO_BLUEBEAM = 'DisplayName0';
export const DISPLAY_NAME_EXAMPLE = 'Name_Example';
export const DISPLAY_NAME_CHAR_RANGE = 'DisplayNameCharRange';

export const EMAIL = 'Email';
export const EMAIL_DESCRIPTION = 'EmailDescription';
export const EMAIL_EXAMPLE = 'Email_Example';
export const EMAIL_ALREADY_EXISTS = 'EmailAlreadyExists0';
export const ENTER_VALID_EMAIL = 'PleaseEnterAValidEmail';

export const PASSWORD = 'Password';
export const PASSWORD_REQUIREMENT_HTML = 'Password_Requirement_HTML';
export const ENTER_VALID_PASSWORD = 'PleaseEnterAValidPassword';
export const NEW_PASSWORD = 'NewPassword';
export const ENTER_NEW_PASSWORD = 'PleaseEnterANewPassword';
export const NEW_PASSWORD_CANNOT_BE_SAME_AS_OLD = 'NewPasswordCantBeTheSameAsOldPassword';
export const ENTER_VALID_NEW_PASSWORD = 'PleaseEnterAValidPassword';
export const CURRENT_PASSWORD = 'CurrentPassword';
export const CURRENT_PASSWORD_NOT_CORRECT = 'CurrentPasswordNotCorrect';

export const CONFIRM_PASSWORD = 'ConfirmPassword';
export const ENTER_VALID_CONFIRM_PASSWORD = 'PleaseEnterAValidPassword';
export const CONFIRM_PASSWORD_MISMATCH = 'Password_ConfirmationMismatch';
export const CONFIRM_NEW_PASSWORD = 'ConfirmNewPassword';

export const SSO_CONTACT_ADMINISTRATOR = 'SSOContactAdministrator';
export const SSO_PROVIDER = 'SSOProvider';
export const SSO_SUPPORT_URL = 'SSOSupportUrl';
export const IDP_TYPE = 'IDPType';
export const IDP_TYPE_OTHER = 'IDPTypeOther';
export const CUSTOMER_NAME_CHAR_RANGE = 'CustomerNameCharRange';
export const CUSTOMER_NAME = 'CustomerName';
export const CUSTOMER_NAME_EXAMPLE = 'CustomerName_Example';
export const CLIENT_ID = 'ClientIdAzure';
export const CLIENT_ID_EXAMPLE = 'ClientId_Example';
export const CHAR_MAX_256 = 'CharMax256';
export const CHAR_MAX_2048 = 'CharMax2048';
export const CLIENT_SECRET = 'ClientSecretAzure';
export const ISSUER = 'Issuer';
export const ISSUER_EXAMPLE = 'Issuer_Example';
export const DOMAINS = 'Domains';
export const DOMAINS_EXAMPLE = 'Domains_Example';
export const PREFERRED_LANGUAGE = 'PreferredLanguage';
export const BLUEBEAM_HONORING_LANGUAGE_SELECTION = 'BluebeamHonoringLanguageSelection';

export const PREFERRED_LANGUAGE_SUCCESSFULLY_CHANGED = 'PreferredLanguageChangedSuccessfully';
export const CHANGES_TO_PREFERRED_LANGUAGE_IN_ALL_BB_CLOUD = 'PreferredLanguageBBCloud';

export const CREATE_AN_ACCOUNT = 'CreateAnAccount';
export const CREATE_ACCOUNT = 'CreateAccount';
export const LOG_IN_SIGN_IN = 'LoginSignIn';
export const TOOLTIP_NAME = 'Name_Tooltip';

export const CHECK_YOUR_INBOX = 'CheckYourInbox';
export const CONFIRM_YOUR_EMAIL = 'ConfirmYourEmail';
export const THEN_SIGN_IN_HERE = 'ThenSignInHere';

export const SAVE = 'Save';
export const CHANGE = 'Change';
export const CANCEL = 'Cancel';
export const LOGOUT = 'Logout';

export const NAME = 'Name';

export const OOPS_SOMETHING_WENT_WRONG = 'OopsSomethingWentWrongHeader';
export const ITS_NOT_YOU_ITS_US = 'ItsNotYouItsUsTryLink';
export const LINK_OUT_MANAGE_ACCOUNT = 'LinkOutManageAccount';
export const LINK_OUT_BLUEBEAM = 'LinkOutBluebeam';
export const LINK_OUT_GATEWAY = 'LinkOutGateway';
export const LINK_OUT_CLOUD = 'LinkOutCloud';
export const LINK_OUT_WEBSTORE = 'LinkOutWebstore';
export const LINK_OUT_SUPPORT = 'LinkOutSupport';
export const LINK_OUT_CONTACT = 'LinkOutContact';

export const BLUEBEAM_ID_YOUR_EMAIL_ADDRESS = 'BluebeamIDYourEmailAddress';
export const RESET_PASSWORD = 'ResetPassword';
export const SEND_RESET_PASSWORD_INSTRUCTIONS = 'SendResetPasswordInstructions';
export const ONE_TIME_CODE_IS_INVALID_OR_EXPIRED = 'OneTimeCodeIsInvalidOrExpired';

export const CHECK_YOUR_EMAIL = 'CheckYourEmail';
export const FORGOT_PASSWORD_SUCCESS = 'ForgotPassword_Success';
export const FORGOT_PASSWORD_SUCCESS_SPAM = 'ForgotPassword_SuccessSpam';

export const THANK_YOU = 'ThankYou';
export const CONFIRM_EMAIL_SUCCESS = 'ConfirmEmail_Success';

export const RESET_YOUR_BLUEBEAM_ID_PASSWORD = 'ResetYourBluebeamIdPassword';
export const BLUEBEAM_ID = 'BluebeamId';
export const UPDATE_PASSWORD = 'UpdatePassword';

export const PASSWORD_SUCCESSFULLY_CHANGED = 'PasswordChanged';
export const PLEASE_VERIFY_EMAIL = 'PleaseVerifyYourEmailToMakeChanges';
export const EMAIL_ME_UPDATED_LINK = 'EmailMeUpdatedVerificationLink';
export const WE_HAVE_SENT_YOU_UPDATED_LINK = 'UpdatedVerificationLinkEmailed';

export const NAME_CHANGED_SUCCESSFULLY = 'NameChangedSuccessfully';
export const PASSWORD_CHANGED_SUCCESSFULLY = 'PasswordChangedSuccessfully';
export const DISPLAY_NAME_CHANGED_SUCCESSFULLY = 'DisplayNameChangedSuccessfully';
export const VERIFICATION_EMAIL_SENT_PLEASE_VERIFY = 'VerificationEmailSentPleaseVerify';
export const WE_WILL_SEND_YOU_CONFIRMATION_EMAIL =
  'WellSendYouAVerificationEmailAfterwardsConfirmInTheEmail';

export const LOGGED_OUT = 'LoggedOutHeader';

export const ALREADY_REGISTERED = 'AlreadyRegistered'

export const LANGUAGE_OPTIONS = [
  { label: 'Dansk', value: 'da-DK' },
  { label: 'Nederlands', value: 'nl-NL' },
  { label: 'English (US)', value: 'en-US' },
  { label: 'English (UK)', value: 'en-GB' },
  { label: 'Suomi', value: 'fi-FI' },
  { label: 'Français (France)', value: 'fr-FR' },
  { label: 'Deutsch (Deutschland)', value: 'de-DE' },
  { label: 'Italiano', value: 'it-IT' },
  { label: '日本語', value: 'ja-JP' },
  { label: '한국어', value: 'ko-KR' },
  { label: 'Norsk (Bokmål)', value: 'no-NO' },
  { label: 'Español (España)', value: 'es-ES' },
  { label: 'Svenska', value: 'sv-SE' }
];
