import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { Button } from '@bluebeam/react-skyline-button';
import { FormInput } from '../../components/FormInput';
import { FormLabel } from '../../components/FormLabel';
import { ValidationError } from '../../components/ValidationError';
import { useValidateForm } from '../../hooks/useValidateForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/password';
import { MessagePopUp } from '../../components/MessagePopUp';
import { useHandlePopUp } from '../../hooks/useHandlePopUp';

import * as formConstants from '../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const ResetPassword: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('e') || '';
    const tokenParam = params.get('t') || '';

    if (emailParam == '' || tokenParam == '') {
      navigate('/error');
    }

    setEmail(emailParam);
    setToken(tokenParam);
  }, [location.search]);

  const { formValues, formErrors, handleChange, areSpecificFormFieldsValid } = useValidateForm();
  const { showPopUp, setShowPopUp, handleClosePopUp } = useHandlePopUp();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      areSpecificFormFieldsValid([
        formConstants.NEW_PASSWORD_FIELD,
        formConstants.NEW_CONFIRM_PASSWORD_FIELD
      ])
    ) {
      let timer: NodeJS.Timeout;
      try {
        const response = await resetPassword(
          email,
          formValues[formConstants.NEW_PASSWORD_FIELD],
          token
        );

        if (response.ok) {
          navigate('/resetpassword/confirmation');
        } else if (response.status === 403) {
          setShowPopUp(true);

          // Navigate to error page after popup disappears
          timer = setTimeout(() => {
            navigate('/error');
          }, 4000);
        } else {
          navigate('/error');
        }
      } catch (error) {
        navigate('/error');
      }

      return () => clearTimeout(timer);
    }
  };

  return (
    <>
      <GenericMessageContainer title={t(translationConstants.RESET_YOUR_BLUEBEAM_ID_PASSWORD)}>
        <form onSubmit={handleSubmit} className="flex flex-col p-7 pt-0">
          <div className="flex flex-col">
            <FormLabel text={t(translationConstants.BLUEBEAM_ID)} />
            <FormInput value={email} isDisabled={true} />
          </div>
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.NEW_PASSWORD)} />
            <FormInput
              type="password"
              value={formValues.newPassword}
              onChange={handleChange(formConstants.NEW_PASSWORD_FIELD)}
              hasError={formErrors.newPassword != ''}
            />
            <ValidationError hidden={!formErrors.newPassword} text={formErrors.newPassword} />
          </div>
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.CONFIRM_NEW_PASSWORD)} />
            <FormInput
              type="password"
              value={formValues.newConfirmPassword}
              onChange={handleChange(formConstants.NEW_CONFIRM_PASSWORD_FIELD)}
              hasError={formErrors.newConfirmPassword != ''}
            />
            <ValidationError
              hidden={!formErrors.newConfirmPassword}
              text={formErrors.newConfirmPassword}
            />
          </div>
          <Button
            className="mt-8 px-6 py-2 self-center bg-primary rounded-[0.25rem] w-fit text-white text-[16px] font-[650] capitalize hover:bg-[#286090]"
            variant="filled"
            type="submit"
          >
            {t(translationConstants.UPDATE_PASSWORD)}
          </Button>
        </form>
      </GenericMessageContainer>
      {showPopUp && (
        <MessagePopUp
          message={t(translationConstants.ONE_TIME_CODE_IS_INVALID_OR_EXPIRED)}
          onClose={handleClosePopUp}
          color="bg-red-100"
        />
      )}
    </>
  );
};
