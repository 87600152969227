import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { FieldValue } from '../../useValidateForm';
import { splitStringToArray } from '../../../utils/strings';
import { updateSSOAdminData } from '../../../services/ssoIdentityProvider';
import { UserDataRequestBody, updateUserAccountData } from '../../../services/manageAccount';

import * as formConstants from '../../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../../utils/constants/i18nTranslationConstants';
import * as rowNameConstants from '../../../components/ProfileSettingsRow';

type useHandleModalArgs = {
  hasFormErrors: () => boolean;
  formErrors: FieldValue;
  resetPasswordFields: () => void;
  areSpecificFormFieldsValid: (fields: (keyof FieldValue)[]) => boolean;
  formValues: FieldValue;
  setFormValues: (values: FieldValue) => void;
  setPopUpMessage: React.Dispatch<React.SetStateAction<string>>;
  profileValues: FieldValue;
  setProfileValues: React.Dispatch<React.SetStateAction<FieldValue>>;
  bbid: string | undefined;
  accessToken: string | null;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPasswordNotCorrectError: () => void;
  setSSOFieldNotValidError: (fieldName: keyof FieldValue) => void;
  setPopUpColor: React.Dispatch<React.SetStateAction<string>>;
  preferredLanguage: string;
};

export type DropdownOptions = {
  label: string;
  value: string;
};

const OLD_PASSWORD = 'oldPassword';
const IDP_TYPE = 'idpType';
const BB_PREFERRED_LANGUAGE = 'bb_preferred_language';

export const useHandleModal = ({
  formValues,
  setFormValues,
  formErrors,
  hasFormErrors,
  setProfileValues,
  profileValues,
  resetPasswordFields,
  areSpecificFormFieldsValid,
  setPopUpMessage,
  setShowPopUp,
  bbid,
  accessToken,
  setCurrentPasswordNotCorrectError,
  setSSOFieldNotValidError,
  setPopUpColor,
  preferredLanguage
}: useHandleModalArgs) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [selectedSsoIDPTypeDropdownValue, setSelectedSsoIDPTypeDropdownValue] =
    useState<string>('Azure');
  const [selectedPreferredLanguageDropdownValue, setSelectedPreferredLanguageDropdownValue] =
    useState<string>(preferredLanguage);
  const [currentRow, setCurrentRow] = useState<string>('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  const ssoIDPTypeDropDownOptions = [
    { label: 'Azure', value: 'Azure' },
    { label: 'Okta', value: 'Okta' },
    { label: t(translationConstants.IDP_TYPE_OTHER), value: 'Other' }
  ];

  // Disable save button in modal if there are any form errors
  useEffect(() => {
    setIsSaveButtonDisabled(hasFormErrors);
  }, [formErrors]);

  useEffect(() => {
    setSelectedPreferredLanguageDropdownValue(preferredLanguage);
  }, [preferredLanguage]);

  const handleSSOIdentityProviderDropdownChange = (value: string) => {
    setSelectedSsoIDPTypeDropdownValue(value);
  };

  const handlePreferredLanguageDropdownChange = (value: string) => {
    setSelectedPreferredLanguageDropdownValue(value);
    i18n.changeLanguage(value);
  };

  const handleChangeButtonClick = (rowName: string) => {
    setCurrentRow(rowName);
    setShowModal(true);
  };

  const handleCloseModal = (shouldInputBeSavedToProfile = false) => {
    if (
      currentRow === rowNameConstants.PASSWORD_SETTINGS_ROW ||
      currentRow === rowNameConstants.SSO_IDENTITY_PROVIDER_ROW
    ) {
      resetPasswordFields();
    }

    if (shouldInputBeSavedToProfile) {
      setProfileValues(formValues);
    } else {
      if (currentRow === rowNameConstants.BB_PREFERRED_LANGUAGE_ROW) {
        i18n.changeLanguage(preferredLanguage);
        setSelectedPreferredLanguageDropdownValue(preferredLanguage);
      }
      setFormValues(profileValues);
    }

    setShowModal(false);
  };

  const getFieldsToCheck = (): (keyof FieldValue)[] => {
    switch (currentRow) {
      case rowNameConstants.NAME_SETTINGS_ROW:
        return [formConstants.FIRST_NAME_FIELD, formConstants.LAST_NAME_FIELD];
      case rowNameConstants.PASSWORD_SETTINGS_ROW:
        return [
          formConstants.CURRENT_PASSWORD_FIELD,
          formConstants.NEW_PASSWORD_FIELD,
          formConstants.NEW_CONFIRM_PASSWORD_FIELD
        ];
      case rowNameConstants.DISPLAY_NAME_SETTINGS_ROW:
        return [formConstants.DISPLAY_NAME_FIELD];
      case rowNameConstants.EMAIL_SETTINGS_ROW:
        return [formConstants.EMAIL_FIELD];
      case rowNameConstants.SSO_IDENTITY_PROVIDER_ROW:
        return [
          formConstants.CLIENT_ID_FIELD,
          formConstants.CUSTOMER_NAME_FIELD,
          formConstants.CLIENT_SECRET_FIELD,
          formConstants.ISSUER_FIELD,
          formConstants.DOMAINS_FIELD
        ];
      default: // rows with no validation
        return [];
    }
  };

  const buildRequestBody = (): UserDataRequestBody => {
    const requestBody: UserDataRequestBody = {};

    switch (currentRow) {
      case rowNameConstants.NAME_SETTINGS_ROW:
        requestBody[formConstants.FIRST_NAME_FIELD] = formValues.firstName;
        requestBody[formConstants.LAST_NAME_FIELD] = formValues.lastName;
        setPopUpMessage(t(translationConstants.NAME_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.PASSWORD_SETTINGS_ROW:
        requestBody[formConstants.PASSWORD_FIELD] = formValues.newPassword;
        requestBody[OLD_PASSWORD] = formValues.currentPassword;
        setPopUpMessage(t(translationConstants.PASSWORD_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.EMAIL_SETTINGS_ROW:
        requestBody[formConstants.EMAIL_FIELD] = formValues.email;
        setPopUpMessage(
          t(translationConstants.VERIFICATION_EMAIL_SENT_PLEASE_VERIFY, {
            where: formValues.email
          })
        );
        break;
      case rowNameConstants.DISPLAY_NAME_SETTINGS_ROW:
        requestBody[formConstants.DISPLAY_NAME_FIELD] = formValues.displayName;
        setPopUpMessage(t(translationConstants.DISPLAY_NAME_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.BB_PREFERRED_LANGUAGE_ROW:
        requestBody[BB_PREFERRED_LANGUAGE] = selectedPreferredLanguageDropdownValue;
        setPopUpMessage(t(translationConstants.PREFERRED_LANGUAGE_SUCCESSFULLY_CHANGED));
        break;
      case rowNameConstants.SSO_IDENTITY_PROVIDER_ROW:
        requestBody[IDP_TYPE] = selectedSsoIDPTypeDropdownValue;
        requestBody[formConstants.CUSTOMER_NAME_FIELD] = formValues.customerName;
        requestBody[formConstants.CLIENT_ID_FIELD] = formValues.clientId;
        requestBody[formConstants.CLIENT_SECRET_FIELD] = formValues.clientSecret;
        requestBody[formConstants.ISSUER_FIELD] = formValues.issuer;
        requestBody[formConstants.DOMAINS_FIELD] = splitStringToArray(formValues.domains);
        break;
      default:
        navigate('/error');
    }

    return requestBody;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const areFormFieldsValid = areSpecificFormFieldsValid(getFieldsToCheck());
    setIsSaveButtonDisabled(areFormFieldsValid);

    if (areFormFieldsValid) {
      setIsSaveButtonLoading(true);
      const requestBody = buildRequestBody();

      try {
        if (currentRow === rowNameConstants.SSO_IDENTITY_PROVIDER_ROW) {
          handleUpdateSSOData(requestBody);
        } else {
          handleUpdateUserData(requestBody);
        }
      } catch (error) {
        navigate('/error');
      }
    }
  };

  const handleUpdateSSOData = async (requestBody: UserDataRequestBody) => {
    const response = await updateSSOAdminData(accessToken!, requestBody);
    if (response.ok) {
      handleCloseModal(true);

      setIsSaveButtonDisabled(false);
      setIsSaveButtonLoading(false);
    } else if (response.status === 400) {
      const errorData = await response.json();
      if (errorData.errorCode === 'InvalidParameterError') {
        setSSOFieldNotValidError(errorData.parameter);
        setIsSaveButtonLoading(false);
      }
    } else {
      navigate('/error');
    }
  };

  const handleUpdateUserData = async (requestBody: UserDataRequestBody) => {
    const response = await updateUserAccountData(bbid!, accessToken!, requestBody);
    if (response.ok) {
      handleCloseModal(true);

      if (currentRow === rowNameConstants.BB_PREFERRED_LANGUAGE_ROW) {
        await i18n.changeLanguage(selectedPreferredLanguageDropdownValue);
      }

      setIsSaveButtonDisabled(false);
      setIsSaveButtonLoading(false);

      setPopUpColor('bg-green-100');

      setShowPopUp(true);
    } else if (response.status === 403) {
      setCurrentPasswordNotCorrectError();
    } else if (response.status === 409) {
      setPopUpMessage(t(translationConstants.EMAIL_ALREADY_EXISTS, { where: formValues.email }));
      handleCloseModal(false);

      setIsSaveButtonDisabled(false);
      setIsSaveButtonLoading(false);

      setPopUpColor('bg-red-100');
      setShowPopUp(true);
    } else {
      navigate('/error');
    }
  };

  return {
    showModal,
    handleChangeButtonClick,
    handleSubmit,
    handleCloseModal,
    isSaveButtonDisabled,
    isSaveButtonLoading,
    currentRow,
    ssoIDPTypeDropDownOptions,
    selectedSsoIDPTypeDropdownValue,
    selectedPreferredLanguageDropdownValue,
    handleSSOIdentityProviderDropdownChange,
    handlePreferredLanguageDropdownChange
  };
};
